import * as React from 'react'
import "../styles/form-dl.css"
import { navigate } from "gatsby"
import Seo from "../components/seo"


const FormPage = () => {
  return (
    <>
      <Seo title="Eーzy資料ダウンロードフォーム" description="E-zy資料ダウンロードフォームです" />

      <div className="formDl">
        <div className="formDl__wrapper">

          <h2 className="formDl__heading">資料ダウンロードフォーム</h2>
          <p className="formDl__text">IT導入補助金を活用したEC構築サービス「E-zy（イージー）」の資料をダウンロードいただけます。
            以下の項目にご回答ください。</p>
          <form
            onSubmit={() => { navigate("/formsent/") }}
            action="https://docs.google.com/forms/u/3/d/e/1FAIpQLSfinyFulsd1uB7H5bOj2SHzh9ybcCDC2n6cYFs1oZEYuYfPgA/formResponse" //※１ 先ほどのフォームのURL
            target="hidden_iframe"
          >

            <div className="Form">
              <div className="Form-Item">
                <label className="Form-Item-Label" htmlFor="name" required> <span class="Form-Item-Label-Required">必須</span>お名前</label>
                <input className="Form-Item-Input" id="name" type="text" name="entry.1766752699" placeholder="山田太郎" required></input>
              </div>

              <div className="Form-Item">
                <label className="Form-Item-Label" required> <span class="Form-Item-Label-Required">必須</span>事業形態</label>
                <div className="Form-Item-Radio">

                  <label htmlFor="rating1">
                    <input
                      className="Form-Item-Radio-Detail"
                      type="radio"
                      name="entry.1910431360"
                      aria-label="1"
                      aria-pointset="1"
                      value="法人"
                      id="rating1"
                    />法人</label>
                  <label htmlFor="rating2">
                    <input
                      className="Form-Item-Radio-Detail"
                      type="radio"
                      name="entry.1910431360"
                      aria-label="2"
                      aria-pointset="2"
                      value="個人"
                      id="rating2"
                    />個人</label>
                </div>
              </div>

              <div className="Form-Item">
                <label className="Form-Item-Label" htmlFor="company" required> <span class="Form-Item-Label-Required">必須</span>会社名・屋号</label>
                <input className="Form-Item-Input" id="company" type="text" name="entry.1559688715" placeholder="株式会社〇〇" required></input>
              </div>
              <div className="Form-Item">
                <label className="Form-Item-Label" htmlFor="phone"><span class="Form-Item-Label-Option">任意</span>電話番号</label>
                <input className="Form-Item-Input" id="phone" type="tel" name="entry.1827534827" placeholder="000-0000-0000"></input>
              </div>
              <div className="Form-Item">
                <label className="Form-Item-Label" htmlFor="email" required> <span class="Form-Item-Label-Required">必須</span>メールアドレス</label>
                <input className="Form-Item-Input" id="email" type="text" name="entry.1248085407" placeholder="email@example.com" required></input>
              </div>
              <div className="Form-Item">
                <label className="Form-Item-Label" required> <span class="Form-Item-Label-Required">必須</span>ご検討状況</label>
                <div className="Form-Item-Radio Form-Item-Radio__2">

                  <label className='chance-item' htmlFor="chance1">
                    <input
                      className="Form-Item-Radio-Detail"
                      type="radio"
                      name="entry.116422716"
                      aria-label="1"
                      aria-pointset="1"
                      value="情報収集中"
                      id="chance1"
                    />情報収集中</label>
                  <label className='chance-item' htmlFor="chance2">
                    <input
                      className="Form-Item-Radio-Detail"
                      type="radio"
                      name="entry.116422716"
                      aria-label="2"
                      aria-pointset="2"
                      value="ECサイトの構築を検討中"
                      id="chance2"
                    />ECサイトの構築を検討中</label>
                  <label className='chance-item' htmlFor="chance3">
                    <input
                      className="Form-Item-Radio-Detail"
                      type="radio"
                      name="entry.116422716"
                      aria-label="3"
                      aria-pointset="3"
                      value="制作の依頼先を検討中"
                      id="chance3"
                    />制作の依頼先を検討中</label>
                  <label className='chance-item' htmlFor="chance4">
                    <input
                      className="Form-Item-Radio-Detail"
                      type="radio"
                      name="entry.116422716"
                      aria-label="4"
                      aria-pointset="4"
                      value="すぐに制作を始めたい"
                      id="chance4"
                    />すぐに制作を始めたい</label>
                </div>
              </div>
            </div>


            <button className="formDl__button" type="submit">送信する</button>
          </form>
        </div>

        <iframe title="hidden_iframe" name="hidden_iframe" style={{ display: 'none' }}></iframe>
      </div>
      <iframe title="hidden_iframe" name="hidden_iframe" style={{ display: 'none' }}></iframe>
    </>
  )
}
export default FormPage